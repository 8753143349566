import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import {Container, Row, Col, Button} from 'react-bootstrap';
import { React, useState } from 'react';
import { BiFemale, BiMale } from "react-icons/bi";
import { MdFamilyRestroom } from "react-icons/md";
import { FaLocationArrow } from "react-icons/fa";

const App = () => {
  const [err, setErr] = useState(null);
  const [addBtn, setAddBtn] = useState(true);
  const [q1btn, q1btnstate] = useState('');
  const [gender, setGender] = useState('');
  const [fullName, setFullName] = useState('');
  const [loaderPercent, loaderPercentstate] = useState('0%');
  const [q2btn, q2btnstate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [q3btn, q3btnstate] = useState('');
  const [age, setAge] = useState('');
  // eslint-disable-next-line
  const [q4btn, q4btnstate] = useState('');
  const [married, setMarried] = useState(null);
  const [education, setEducation] = useState('');
  const [location, setLocation] = useState('');
  const [rezayat, setRezayat] = useState('');
  const [nahve, setNahve] = useState('');
  const [maharat, setMaharat] = useState('');
  const [challenge, setChallenge] = useState('');
  const [emkanat, setEmkanat] = useState('');
  const [filesKeeping, setFilesKeeping] = useState('');
  const [keepingCost, setKeepingCost] = useState('');
  const [website, setWebsite] = useState('');
  const [divarUsage, setDivarUsage] = useState('');
  const [sheypoorStats, setSheypoorStats] = useState('');
  const [contentCreation, setContentCreation] = useState('');
  const [commission, setCommission] = useState('');
  const [fetures, setFetures] = useState('');
  const [directContact, setDirectContact] = useState('');
  const [timeToAdd, setTimeToAdd] = useState('');
  const [postAd, setPostAd] = useState('');
  const [filesAccess, setFilesAccess] = useState('');
  const [priority, setPriority] = useState('');
  const [mosharekat, setMosharekat] = useState('');
  const [estateInput, setEstateInput] = useState('');
  const [otherSales, setOtherSales] = useState('');
  const [pinAd, setPinAd] = useState('');
  const [desc, setDesc] = useState('');


  const uploadData = async () => {
    setAddBtn(false);
    setErr(false);
    const theBtn = document.querySelector('#addBtn');
    theBtn.classList.add('hidden');
    const dataToUpload = {
        "gender": gender,
        "fullName": fullName,
        "phoneNumber": phoneNumber,
        "age": age,
        "married": married,
        "education": education,
        "location": location,
        "rezayat": rezayat,
        "nahve": nahve,
        "maharat": maharat,
        "challenge": challenge,
        "emkanat": emkanat,
        "filesKeeping": filesKeeping,
        "keepingCost": keepingCost,
        "website": website,
        "divarUsage": divarUsage,
        "sheypoorStats": sheypoorStats,
        "contentCreation": contentCreation,
        "commission": commission,
        "fetures": fetures,
        "directContact": directContact,
        "timeToAdd": timeToAdd,
        "postAd": postAd,
        "filesAccess": filesAccess,
        "priority": priority,
        "mosharekat": mosharekat,
        "estateInput": estateInput,
        "otherSales": otherSales,
        "pinAd": pinAd,
        "desc":  desc
      }
    axios.post('https://persona-from.herokuapp.com/api/add', dataToUpload)
    .then (response => {
      console.log(response);
      document.querySelector('.question-29').classList.add('hidden'); 
      document.querySelector('.question-30').classList.remove('hidden'); 
      loaderPercentstate('100%')
      setAddBtn(true);
    })
    .catch (error => {
      console.log(error);
      document.querySelector('.question-30').classList.add('hidden'); 
      document.querySelector('.question-29').classList.remove('hidden'); 
      loaderPercentstate('100%')
      setErr(true);
      setAddBtn(true);
      const theBtn = document.querySelector('#addBtn');
      theBtn.classList.remove('hidden');
    });
  };
  return (
    <div className='App align-items-center justify-content-center d-flex flex-column bg-white'>
      {loaderPercent === '0%' ? '' :
      <section className='position-fixed top-0 left-0 right-0 content text-center d-flex flex-row w-100 mx-0 my-0 p-0'>
            <div class="progress ltr bg-dark m-0 p-0 rounded-0"  style={{height: '3px', width: '100%'}}>
              <div class="progress-bar progress-bar-striped progress-bar-animated m-0 p-0 bg-info" style={{width: loaderPercent}} role="progressbar"></div>
            </div>
      </section>
      }
      <section className='content my-2 text-center d-flex flex-row w-100'>
        <Container>
          <Row>
            <Col>
              <div className='p-3'>
                <div className='questions text-dark'>

                  {/* <!-- Question 0 --> */}
                  <div className='question question-0'>
                    <div className='question-title'>
                    سلام و وقت بخیر<br />
                    از اینکه وقت گرانبهای خود را در اختیار ما قرار می‌دهید تا در خدمت‌رسانی هرچه بهتر ما را یاری نمائید، سپاسگزاریم.
                    <br />
                    پیش از آغاز به‌اطلاعتان می‌رسانیم کلیه مواردی که در این پرسشنامه وارد خواهیدکرد، نزد ما باقی خواهد ماند و در اختیار شخص سومی قرار نخواهد گرفت.
                    <br />
                    برای شروع اماده‌اید؟
                    <br />
                    </div>
                    <div className='buttons d-flex flex-row justify-content-center w-100 align-items-center'>
                      <div className='next-button w-100'>
                        <Button 
                        className='btn-block w-100'
                        variant='primary' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-0').classList.add('hidden'); 
                            document.querySelector('.question-1').classList.remove('hidden'); 
                            loaderPercentstate('3%')
                            } 
                          }>
                            شروع
                          </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 1 --> */}
                  <div className='question question-1 hidden'>
                    <div className='answer mt-3'>
                      <Row>
                        <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                          <div className="form-check form-switch form-check-inline p-0 m-0">
                            <input className="form-check-input d-none" type="checkbox" id="gender"
                            onChange={
                              e => {
                                if (e.target.checked) {
                                  setGender('آقا');
                                } else {
                                  setGender('خانم');
                                }
                              }
                            }
                            />
                            <label className="form-check-label border text-center border-grey p-3 rounded" htmlFor='gender'>
                              {gender === 'آقا' ? 
                              <div><BiMale size='25' /> آقای </div>
                              :
                              <div><BiFemale size='25' /> خانم</div>
                              }
                            </label>
                          </div>
                        </Col>
                        <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={9} md={9}>
                          <input type="text" name="fullname" placeholder="نام و نام خانوادگی" className='answer-1 form-control form-control-lg my-3 text-center' onChange={
                              e => {
                                if (e.target.value.length > 3) {
                                  q1btnstate(true);
                                  setFullName(e.target.value);

                                } else {
                                  q1btnstate(false) 
                                }
                              }
                            } />
                        </Col>
                      </Row>
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {q1btn ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-1').classList.add('hidden'); 
                              document.querySelector('.question-2').classList.remove('hidden'); 
                              loaderPercentstate('6%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-1').classList.add('hidden'); 
                              document.querySelector('.question-0').classList.remove('hidden'); 
                              loaderPercentstate('0%')
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 2 --> */}
                  <div className='question question-2 hidden'>
                    <div className='question-title'>
                      {gender ? <span>آقای</span> : <span>خانم</span>} {fullName} گرامی، از آشنایی شما خوشوقتیم. لطفا شماره تلفن همراه خود را وارد کنید:
                    </div>
                    <div className='answer'>
                      <input type="text" name="phoneNumber" placeholder="مانند: 09123456789" className='answer-2 form-control form-control-lg my-3 text-center' onChange={
                          e => {
                            if (e.target.value.length === 11) {
                              q2btnstate(true);
                              setPhoneNumber(e.target.value);

                            } else {
                              q2btnstate(false) 
                            }
                          }
                        } />
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {q2btn ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-2').classList.add('hidden'); 
                              document.querySelector('.question-3').classList.remove('hidden'); 
                              loaderPercentstate('9%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-2').classList.add('hidden'); 
                              document.querySelector('.question-1').classList.remove('hidden'); 
                              loaderPercentstate('3%')
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 3 --> */}
                  <div className='question question-3 hidden'>
                    <div className='question-title'>
                      سن خود را وارد کنید:
                    </div>
                    <div className='answer'>
                      <input type="text" name="age" placeholder="مانند: 35" className='answer-3 form-control form-control-lg my-3 text-center' onChange={
                          e => {
                            if (e.target.value.length === 2) {
                              q3btnstate(true);
                              setAge(e.target.value);

                            } else {
                              q3btnstate(false) 
                            }
                          }
                        } />
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {q3btn ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-3').classList.add('hidden'); 
                              document.querySelector('.question-4').classList.remove('hidden'); 
                              loaderPercentstate('12%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-3').classList.add('hidden'); 
                              document.querySelector('.question-2').classList.remove('hidden'); 
                              loaderPercentstate('6%')
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 4 --> */}
                  <div className='question question-4 hidden'>
                    <div className='answer mt-3'>
                      <Row>
                        <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                          <div className="form-check form-switch form-check-inline p-0 m-0">
                              <input className="form-check-input d-none" type="checkbox" id="marriage"
                                onChange={
                                  e => {
                                    if (e.target.checked) {
                                      setMarried('متاهل');
                                    } else {
                                      setMarried('مجرد');
                                    }
                                  }
                                }
                              />
                              <label className="form-check-label mb-2" htmlFor='marriage'>
                                {married === 'متاهل' ? 
                                <div className='border text-center border-grey p-3 rounded'><MdFamilyRestroom size='25' /> متاهل</div>
                                :
                                <div className='border text-center border-grey p-3 rounded'><BiMale size='25' /> مجرد</div>
                                }
                              </label>
                          </div>
                        </Col>
                      </Row>                        
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {q4btn || !q4btn ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-4').classList.add('hidden'); 
                              document.querySelector('.question-5').classList.remove('hidden'); 
                              loaderPercentstate('15%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-4').classList.add('hidden'); 
                              document.querySelector('.question-3').classList.remove('hidden');
                              loaderPercentstate('9%') 
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 5 --> */}
                  <div className='question question-5 hidden'>
                    <div className='answer mt-3'>
                      <Row>
                        <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                          <div className="form-check form-switch form-check-inline p-0 m-0">
                            <select className="form-select form-select-lg ltr" onChange={
                              e => {
                                e.preventDefault();
                                setEducation(e.target.value);
                              }
                            }>
                              <option className='ltr' value='' disabled selected>میزان تحصیلات</option>
                              <option className='ltr' value="زیر دیپلم">زیر دیپلم</option>
                              <option className='ltr' value="دیپلم">دیپلم</option>
                              <option className='ltr' value="کاردانی">کاردانی</option>
                              <option className='ltr' value="کارشناسی">کارشناسی</option>
                              <option className='ltr' value="کارشناسی ارشد">کارشناسی ارشد</option>
                              <option className='ltr' value="دکترا">دکترا</option>
                            </select>
                          </div>
                        </Col>
                      </Row>                       
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {education ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-5').classList.add('hidden'); 
                              document.querySelector('.question-6').classList.remove('hidden'); 
                              loaderPercentstate('18%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-5').classList.add('hidden'); 
                              document.querySelector('.question-4').classList.remove('hidden'); 
                              loaderPercentstate('12%')
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 6 --> */}
                  <div className='question question-6 hidden'>
                    <div className='question-title'>
                    <FaLocationArrow /> آدرس محل کار
                    </div>
                    <div className='answer mt-3'>
                      <Row>
                        <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                          <textarea className='textarea form-control form-control-lg' placeholder='مانند: یزد، بلوار خامنه ای - نبش خیابان 40'
                          onChange={
                            e => {
                              if (e.target.value.length > 5) {
                                setLocation(e.target.value);
                              } else {
                                setLocation('');
                              }
                            }
                          }
                          ></textarea>
                        </Col>
                      </Row>                       
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {location ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-6').classList.add('hidden'); 
                              document.querySelector('.question-7').classList.remove('hidden'); 
                              loaderPercentstate('21%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-6').classList.add('hidden'); 
                              document.querySelector('.question-5').classList.remove('hidden');
                              loaderPercentstate('15%') 
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 7 --> */}
                  <div className='question question-7 hidden'>
                    <div className='question-title'>
                    میزان رضایت خود از کسب و کارتان را با ذکر دلیل توضیح دهید:
                    </div>
                    <div className='answer mt-3'>
                      <Row>
                        <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                          <textarea className='textarea form-control form-control-lg'
                          onChange={
                            e => {
                              if (e.target.value.length > 15) {
                                setRezayat(e.target.value);
                              } else {
                                setRezayat('');
                              }
                            }
                          }
                          ></textarea>
                        </Col>
                      </Row>                       
                    </div>
                    <div className='buttons d-flex flex-row justify-content-between'>
                      <div className='next-button'>
                        {rezayat ?
                        <Button 
                          variant='success' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-7').classList.add('hidden'); 
                              document.querySelector('.question-8').classList.remove('hidden'); 
                              loaderPercentstate('24%')
                              } 
                            }>
                              بعدی
                        </Button>
                        : <Button variant='grey' disabled>بعدی</Button>
                          }
                      </div>
                      <div className='previous-button'>
                        <Button 
                          variant='danger' 
                          onClick={ 
                            e => {
                              document.querySelector('.question-7').classList.add('hidden'); 
                              document.querySelector('.question-6').classList.remove('hidden');
                              loaderPercentstate('18%') 
                              } 
                            }>
                              برگشت
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Of Question --> */}

                  {/* <!-- Question 8 --> */}
                  <div className='question question-8 hidden'>
                  <div className='question-title'>
                    نحوه عملکرد و کارکرد خود را در یک روز عادی بیان کنید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setNahve(e.target.value);
                            } else {
                              setNahve('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {nahve ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-8').classList.add('hidden'); 
                            document.querySelector('.question-9').classList.remove('hidden'); 
                            loaderPercentstate('27%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-8').classList.add('hidden'); 
                            document.querySelector('.question-7').classList.remove('hidden');
                            loaderPercentstate('21%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 9 --> */}
                <div className='question question-9 hidden'>
                  <div className='question-title'>
                    چند مورد از مهارت‌ها و دانش مورد نیاز برای انجام شغل شما را نام ببرید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setMaharat(e.target.value);
                            } else {
                              setMaharat('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {maharat ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-9').classList.add('hidden'); 
                            document.querySelector('.question-10').classList.remove('hidden'); 
                            loaderPercentstate('30%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-9').classList.add('hidden'); 
                            document.querySelector('.question-8').classList.remove('hidden');
                            loaderPercentstate('24%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 10 --> */}
                <div className='question question-10 hidden'>
                  <div className='question-title'>
                    چالش‌هایی که در شغل خود با آن مواجه هستید را ذکر کنید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setChallenge(e.target.value);
                            } else {
                              setChallenge('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {challenge ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-10').classList.add('hidden'); 
                            document.querySelector('.question-11').classList.remove('hidden'); 
                            loaderPercentstate('33%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-10').classList.add('hidden'); 
                            document.querySelector('.question-9').classList.remove('hidden');
                            loaderPercentstate('27%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 11 --> */}
                <div className='question question-11 hidden'>
                  <div className='question-title'>
                    چه امکاناتی به پیشرفت و بهبود شغل شما منجر خواهد شد؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setEmkanat(e.target.value);
                            } else {
                              setEmkanat('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {emkanat ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-11').classList.add('hidden'); 
                            document.querySelector('.question-12').classList.remove('hidden'); 
                            loaderPercentstate('36%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-11').classList.add('hidden'); 
                            document.querySelector('.question-10').classList.remove('hidden');
                            loaderPercentstate('30%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 12 --> */}
                <div className='question question-12 hidden'>
                  <div className='question-title'>
                    درحال حاضر فایل‌های خود را به چه صورت نگداری می نمایید؟ توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setFilesKeeping(e.target.value);
                            } else {
                              setFilesKeeping('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {filesKeeping ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-12').classList.add('hidden'); 
                            document.querySelector('.question-13').classList.remove('hidden'); 
                            loaderPercentstate('39%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-12').classList.add('hidden'); 
                            document.querySelector('.question-11').classList.remove('hidden');
                            loaderPercentstate('33%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 13 --> */}
                <div className='question question-13 hidden'>
                  <div className='question-title'>
                    هزینه‌ی نگهداری فایل‌ها برای شما بصورت ماهانه به چه میزان است؟  
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 5) {
                              setKeepingCost(e.target.value);
                            } else {
                              setKeepingCost('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {keepingCost ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-13').classList.add('hidden'); 
                            document.querySelector('.question-14').classList.remove('hidden'); 
                            loaderPercentstate('42%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-13').classList.add('hidden'); 
                            document.querySelector('.question-12').classList.remove('hidden');
                            loaderPercentstate('36%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 14 --> */}
                <div className='question question-14 hidden'>
                  <div className='question-title'>
                    آیا درحال حاضر وبسایت فعالی برای خود دارید؟ از آن به چه صورت استفاده میکنید؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setWebsite(e.target.value);
                            } else {
                              setWebsite('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {website ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-14').classList.add('hidden'); 
                            document.querySelector('.question-15').classList.remove('hidden'); 
                            loaderPercentstate('45%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-14').classList.add('hidden'); 
                            document.querySelector('.question-13').classList.remove('hidden');
                            loaderPercentstate('39%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 15 --> */}
                <div className='question question-15 hidden'>
                  <div className='question-title'>
                    آیا از اپلیکیشن دیوار استفاده میکنید؟ چه امکاناتی در این اپلیکیشن ممکن است به فروش و یا اجاره سریعتر ملک منجر شود؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setDivarUsage(e.target.value);
                            } else {
                              setDivarUsage('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {divarUsage ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-15').classList.add('hidden'); 
                            document.querySelector('.question-16').classList.remove('hidden'); 
                            loaderPercentstate('48%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-15').classList.add('hidden'); 
                            document.querySelector('.question-14').classList.remove('hidden');
                            loaderPercentstate('42%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 16 --> */}
                <div className='question question-16 hidden'>
                  <div className='question-title'>
                    آیا از سرویس‌های مشابه مانند شیپور استفاده می کنید؟ دلیل خود را توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 8) {
                              setSheypoorStats(e.target.value);
                            } else {
                              setSheypoorStats('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {sheypoorStats ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-16').classList.add('hidden'); 
                            document.querySelector('.question-17').classList.remove('hidden'); 
                            loaderPercentstate('51%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-16').classList.add('hidden'); 
                            document.querySelector('.question-15').classList.remove('hidden');
                            loaderPercentstate('45%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 17 --> */}
                <div className='question question-17 hidden'>
                  <div className='question-title'>
                    آیا تولید محتوای مربوط به مراحل اخذ مجوزها، پروانه‌ها و کمیسیون‌ها برای کسب و کار شما مفید است؟ توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setContentCreation(e.target.value);
                            } else {
                              setContentCreation('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {contentCreation ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-17').classList.add('hidden'); 
                            document.querySelector('.question-18').classList.remove('hidden'); 
                            loaderPercentstate('54%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-17').classList.add('hidden'); 
                            document.querySelector('.question-16').classList.remove('hidden');
                            loaderPercentstate('48%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 18 --> */}
                <div className='question question-18 hidden'>
                  <div className='question-title'>
                    اگر یکی از فایل‌های شما توسط شخص ثالثی اعم از همکار و غیر همکار به فروش یا اجاره برود، حاضر به پرداخت کمیسیون به وی هستید؟ میزان ان را مشخص کنید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setCommission(e.target.value);
                            } else {
                              setCommission('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {commission ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-18').classList.add('hidden'); 
                            document.querySelector('.question-19').classList.remove('hidden'); 
                            loaderPercentstate('57%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-18').classList.add('hidden'); 
                            document.querySelector('.question-17').classList.remove('hidden');
                            loaderPercentstate('51%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 19 --> */}
                <div className='question question-19 hidden'>
                  <div className='question-title'>
                    آیا امکاناتی نظیر نمودار قیمتی ملک، عرف قیمت منطقه، فواصل با مکان‌های عمومی، مقایسه بین املاک، تراکم فروش و اجاره و ... برای کسب و کار شما مفید است؟ لطفا توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setFetures(e.target.value);
                            } else {
                              setFetures('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {fetures ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-19').classList.add('hidden'); 
                            document.querySelector('.question-20').classList.remove('hidden'); 
                            loaderPercentstate('60%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-19').classList.add('hidden'); 
                            document.querySelector('.question-18').classList.remove('hidden');
                            loaderPercentstate('54%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 20 --> */}
                <div className='question question-20 hidden'>
                  <div className='question-title'>
                    معمولا چه سوالاتی برای مشتریان شما وجود دارد که منجر به تماس مستقیم خواهد شد؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setDirectContact(e.target.value);
                            } else {
                              setDirectContact('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {directContact ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-20').classList.add('hidden'); 
                            document.querySelector('.question-21').classList.remove('hidden'); 
                            loaderPercentstate('63%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-20').classList.add('hidden'); 
                            document.querySelector('.question-19').classList.remove('hidden');
                            loaderPercentstate('57%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 21 --> */}
                <div className='question question-21 hidden'>
                  <div className='question-title'>
                  برای ورود اطلاعات یک ملک در دیوار و اپلیکیشن‌های مشابه چه میزان زمان صرف می‌کنید؟ چقدر از این زمان صرف ورود اطلاعات غیر ضروری می گردد؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setTimeToAdd(e.target.value);
                            } else {
                              setTimeToAdd('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {timeToAdd ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-21').classList.add('hidden'); 
                            document.querySelector('.question-22').classList.remove('hidden'); 
                            loaderPercentstate('66%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-21').classList.add('hidden'); 
                            document.querySelector('.question-20').classList.remove('hidden');
                            loaderPercentstate('60%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 22 --> */}
                <div className='question question-22 hidden'>
                  <div className='question-title'>
                    برای ثبت اگهی در دیوار بطور میانگین در هر دوره چقدر هزینه می کنید؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setPostAd(e.target.value);
                            } else {
                              setPostAd('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {postAd ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-22').classList.add('hidden'); 
                            document.querySelector('.question-23').classList.remove('hidden'); 
                            loaderPercentstate('69%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-22').classList.add('hidden'); 
                            document.querySelector('.question-21').classList.remove('hidden');
                            loaderPercentstate('63%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 23 --> */}
                <div className='question question-23 hidden'>
                  <div className='question-title'>
                    برای دسترسی به فایل‌های موجودتان و جستجوی ملک براساس خواسته مشتری چگونه عمل می کنید؟ این فرآیند چقدر زمان لازم دارد؟
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setFilesAccess(e.target.value);
                            } else {
                              setFilesAccess('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {filesAccess ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-23').classList.add('hidden'); 
                            document.querySelector('.question-24').classList.remove('hidden'); 
                            loaderPercentstate('72%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-23').classList.add('hidden'); 
                            document.querySelector('.question-22').classList.remove('hidden');
                            loaderPercentstate('66%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 24 --> */}
                <div className='question question-24 hidden'>
                  <div className='question-title'>
                    کدام برای کسب و کار شما اهمیت بیشتری دارد؟ مدیریت سریعتر و بهتر فایل‌ها و یا تعداد مشتری؟ لطفا توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setPriority(e.target.value);
                            } else {
                              setPriority('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {priority ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-24').classList.add('hidden'); 
                            document.querySelector('.question-25').classList.remove('hidden'); 
                            loaderPercentstate('75%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-24').classList.add('hidden'); 
                            document.querySelector('.question-23').classList.remove('hidden');
                            loaderPercentstate('69%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 25 --> */}
                <div className='question question-25 hidden'>
                  <div className='question-title'>
                   آیا تمایل دارید با همکاران و مشتریان خارج از استان یا شهر مشارکتی داشته باشید؟ توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setMosharekat(e.target.value);
                            } else {
                              setMosharekat('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {mosharekat ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-25').classList.add('hidden'); 
                            document.querySelector('.question-26').classList.remove('hidden'); 
                            loaderPercentstate('78%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-25').classList.add('hidden'); 
                            document.querySelector('.question-24').classList.remove('hidden');
                            loaderPercentstate('72%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 26 --> */}
                <div className='question question-26 hidden'>
                  <div className='question-title'>
                    بطور میانگین در یک دوره یکماهه، چه تعداد فایل و ملک نزد شما ثبت می گردد؟ برای فروش و اجاره را تفکیک کنید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setEstateInput(e.target.value);
                            } else {
                              setEstateInput('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {estateInput ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-26').classList.add('hidden'); 
                            document.querySelector('.question-27').classList.remove('hidden'); 
                            loaderPercentstate('81%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-26').classList.add('hidden'); 
                            document.querySelector('.question-25').classList.remove('hidden');
                            loaderPercentstate('75%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 27 --> */}
                <div className='question question-27 hidden'>
                  <div className='question-title'>
                    آیا مایل به مشاهده آمار فروش و یا تعداد زنگ‌خور دیگر همکارانتان هستید؟ آیا تمایل دارید تا دیگران این اطلاعات مربوط به شما را مشاهده کنند؟ توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setOtherSales(e.target.value);
                            } else {
                              setOtherSales('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {otherSales ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-27').classList.add('hidden'); 
                            document.querySelector('.question-28').classList.remove('hidden'); 
                            loaderPercentstate('88%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-27').classList.add('hidden'); 
                            document.querySelector('.question-26').classList.remove('hidden');
                            loaderPercentstate('78%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 28 --> */}
                <div className='question question-28 hidden'>
                  <div className='question-title'>
                    آیا با هزینه‌ی بیشتر برای قراردادن آگهی خود در بالای دیگر آگهی ها در اپ هایی مانند دیوار موافقت دارید؟ لطفا توضیح دهید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setPinAd(e.target.value);
                            } else {
                              setPinAd('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {pinAd ?
                      <Button 
                        variant='success' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-28').classList.add('hidden'); 
                            document.querySelector('.question-29').classList.remove('hidden'); 
                            loaderPercentstate('92%')
                            } 
                          }>
                            بعدی
                      </Button>
                      : <Button variant='grey' disabled>بعدی</Button>
                        }
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-28').classList.add('hidden'); 
                            document.querySelector('.question-27').classList.remove('hidden');
                            loaderPercentstate('88%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 29 --> */}
                <div className='question question-29 hidden'>
                  {err ? <div className='alert alert-danger'>خطایی رخ داد، مجددا تلاش کنید</div> : ""}
                  <div className='question-title'>
                    در صورتیکه توضیحات بیشتری برای راهنمایی هر چه بهتر ما در ارائه خدمات دارید، لطفا ذکر کنید:
                  </div>
                  <div className='answer mt-3'>
                    <Row>
                      <Col className=' d-flex flex-row align-items-center w-100 justify-content-center' xs={3} md={3}>
                        <textarea className='textarea form-control form-control-lg'
                        onChange={
                          e => {
                            if (e.target.value.length > 3) {
                              setDesc(e.target.value);
                            } else {
                              setDesc('');
                            }
                          }
                        }
                        ></textarea>
                      </Col>
                    </Row>                       
                  </div>
                  <div className='buttons d-flex flex-row justify-content-between'>
                    <div className='next-button'>
                      {desc ?
                      <Button 
                        id='addBtn'
                        variant='success' 
                        onClick={uploadData}
                        >
                            ثبت نهائی
                      </Button>
                      : <Button variant='grey' disabled>ثبت نهائی</Button>
                        }
                        {!addBtn ? <div className=''>در حال ارسال داده‌ها، شکیبا باشید...</div> : ""}
                    </div>
                    <div className='previous-button'>
                      <Button 
                        variant='danger' 
                        onClick={ 
                          e => {
                            document.querySelector('.question-29').classList.add('hidden'); 
                            document.querySelector('.question-28').classList.remove('hidden');
                            loaderPercentstate('96%') 
                            } 
                          }>
                            برگشت
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Of Question --> */}

                {/* <!-- Question 30 --> */}
                <div className='question question-30 hidden'>
                  اطلاعات شما ارسال شد.
                  از مشارکت شما سپاسگزاریم. 
                </div>
                {/* <!-- End Of Question --> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default App